<template>
  <div
    class="ecs-seleeca-search"
    :class="{ 'ecs-seleeca-search--open': !!open }"
    tabindex="-1"
    @keyup.esc="close"
    @focusin="onFocus"
    @focusout="onBlur"
  >
    <label class="ecs-seleeca-search__input-wrapper">
      <input
        ref="input"
        v-model="query"
        class="cs-textstyle-input-text cs-seleeca-search__input"
        :placeholder="placeholder"
        @input="onInput"
        @keyup.up="highlightLast()"
        @keyup.down="highlight(0)"
      />
      <span class="ecs-seleeca-search__search-icon">
        <slot name="icon">
          <i :class="icon"></i>
        </slot>
      </span>
      <span class="ecs-seleeca-search__close-icon" @click.stop="closeList">
        <slot name="icon">
          <i class="cs-icons-close"></i>
        </slot>
      </span>
    </label>
    <div class="ecs-seleeca-search__results-wrapper">
      <div ref="results" class="ecs-seleeca-search__results">
        <div
          v-for="(group, $i) in filteredResults"
          :key="$i"
          class="ecs-seleeca-search__option-group"
        >
          <div
            v-if="group.label"
            class="ecs-seleeca-search__option-group-label"
          >
            {{ group.label }}
          </div>
          <div
            v-for="(option, $j) in group.options"
            :key="$j"
            class="ecs-seleeca-search__option-group-item"
            :class="{
              'ecs-seleeca-search__option-group-item--indent': group.label,
              'ecs-seleeca-search__option-group-item--selected':
                selectedOption && selectedOption.value === option.value,
            }"
            :tabindex="open ? 0 : -1"
            @click="select(option)"
            @keyup.enter="select(option)"
            @keyup.down="highlightNext($event)"
            @keyup.up="highlightPrev($event)"
          >
            {{ option.label }}
          </div>
        </div>
        <div
          v-if="filteredResults.length < 1"
          class="ecs-seleeca-search__option-group-item--no-results"
        >
          {{ noResultsLabel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // Value is the value of the option selected
    value: {
      type: [String, Number],
    },
    noResultsLabel: {
      type: String,
      default: 'No Results',
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
    options: {
      type: Array,
      default: null,
    },
    optionGroups: {
      type: Array,
      default: null,
    },
    // Todo: Search/Filtering function only uses label while searching. We might have to update this and make necessary changes in the code to make it search using other properties like value, id, email etc.
    searchProperties: {
      type: Array,
      default: () => ['label'],
    },
    customSearch: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'cs-icons-search-filled',
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    groupLabelKey: {
      type: String,
      default: 'label',
    },
  },
  data() {
    return {
      open: false,
      query: '',
      inputValue: this.value,
    };
  },
  computed: {
    selectedLabel() {
      const option = this.selectedOption;
      return option && option.label ? option.label : '';
    },
    selectedOption() {
      let option;
      const groups = this.optGroups();
      groups.forEach((group) => {
        group.options.forEach((opt) => {
          if (opt.value === this.inputValue) option = opt;
        });
      });
      return option;
    },

    // Lists of results from searching
    filteredResults() {
      const results = JSON.parse(JSON.stringify(this.optGroups())); // Copy list
      const query = this.query && this.query.toLowerCase().trim();
      if (!query) return results; // Don't search if no query
      if (this.customSearch) return results; // Don't filter if using custom-search

      results.forEach((group) => {
        group._matchCount = group.options.length;
      });
      if (query.length === 0) return results;

      // If group label exists and matches, show all sub-items
      results.forEach((group) => {
        if (group.label && group.label.toLowerCase().indexOf(query) > -1) {
          group._matches = true;
        }
      });

      // Filter for matching options
      results.forEach((group) => {
        if (group._matches) return;
        group.options.forEach((option) => {
          this.searchProperties.forEach((prop) => {
            const opProp = option[prop].toString();
            if (opProp.toLowerCase().indexOf(query) > -1) {
              option._match = true;
            }
          });
        });
        group.options = group.options.filter((option) => option._match);
        group._matchCount = group.options.length;
      });

      const filtered = results.filter((group) => group._matchCount > 0);
      return filtered;
    },
  },
  watch: {
    $route() {
      if (this.$route.name !== this.inputValue) {
        this.query = '';
      }
    },
  },
  created() {
    this.query = this.selectedLabel;
  },
  methods: {
    onBlur() {
      const hasFocus = this.$el.matches('.ecs-seleeca-search:focus-within');
      if (!hasFocus) this.close();
    },
    onFocus() {
      if (!this.open) this.open = true;
    },
    onInput() {
      if (this.query === '') {
        this.inputValue = null;
        this.$emit('input');
      }
      this.$emit('query', this.query);
    },
    select(option) {
      this.inputValue = option.value;
      this.query = option.label;
      this.$emit('select', option);
      this.$emit('input', option.value);
      this.close();
    },
    close() {
      this.open = false;
      this.$refs.input.blur();
      this.$el.blur();
    },
    closeList() {
      this.open = false;
      this.query = '';
    },

    // Map  labels and values
    mapPropertiesForOptions() {
      const mappedOptions = this.options.map((option) => ({
        label: option[this.labelKey],
        value: option[this.valueKey],
      }));
      return mappedOptions;
    },

    mapPropertiesForOptionGroups() {
      const mappedOptionGroups = this.optionGroups.map((group) => ({
        label: group[this.groupLabelKey],
        options: group.options.map((option) => ({
          label: option[this.labelKey],
          value: option[this.valueKey],
        })),
      }));
      return mappedOptionGroups;
    },

    // Standardizes the option groups
    optGroups() {
      if (this.optionGroups) {
        const mappedOptionGroups = this.mapPropertiesForOptionGroups();
        return mappedOptionGroups;
      }
      if (this.options) {
        const mappedOptions = this.mapPropertiesForOptions();
        return [{ options: mappedOptions }];
      }
      return [];
    },

    highlightNext($event) {
      this.highlightChange(1, $event);
    },
    highlightPrev($event) {
      this.highlightChange(-1, $event);
    },
    highlightChange(change, $event) {
      const options = this.$refs.results.querySelectorAll(
        '.ecs-seleeca-search__option-group-item'
      );
      const optArray = Array.prototype.slice.call(options);
      this.highlight(optArray.indexOf($event.target) + change);
    },
    highlightLast() {
      const options = this.$refs.results.querySelectorAll(
        '.ecs-seleeca-search__option-group-item'
      );
      this.highlight(options.length - 1);
    },
    highlight(index) {
      const options = this.$refs.results.querySelectorAll(
        '.ecs-seleeca-search__option-group-item'
      );
      if (index > options.length - 1 || index === -1) {
        this.$refs.input.focus();
      } else {
        options[index].focus();
      }
    },
  },
};
</script>

<style scoped>
/* Input */
.ecs-seleeca-search {
  ---ecs-seleeca-search-border-radius: var(--cs-input-border-radius);
  width: 100%;
  outline: none;
}

.ecs-seleeca-search__input-wrapper {
  position: relative;
  display: block;
  height: 56px;
  overflow: hidden;
  background-color: var(--cs-gray-00);
  border: 1px solid;
  border-color: var(--cs-gray-02);
  border-radius: var(---ecs-seleeca-search-border-radius);
  box-sizing: border-box;
  align-items: center;
  transition: border-color 0.2s;
}
.ecs-seleeca-search--open .ecs-seleeca-search__input-wrapper {
  color: var(--cs-gray-07);
  border-color: var(--cs-gray-03);
  border-bottom-color: var(--cs-gray-02);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ecs-seleeca-search--open .ecs-seleeca-search__search-icon {
  color: var(--cs-gray-07);
}
.cs-seleeca-search__input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 0 var(--cs-form-input-padding-x);
  padding-left: calc(2 * 12px + 18px);
  color: var(--cs-gray-07);
  background-color: transparent;
  height: 100%;
  border: none;
  outline: none;
}
.ecs-seleeca-search__search-icon {
  position: absolute;
  left: 12px;
  top: 17px;
  font-size: 18px;
  color: var(--cs-gray-03);
  transition: color 0.2s;
}

.ecs-seleeca-search--open .ecs-seleeca-search__close-icon {
  position: absolute;
  right: 12px;
  top: 17px;
  font-size: 18px;
  color: var(--cs-gray-03);
  transition: color 0.2s;
  cursor: pointer;
}
.cs-seleeca-search__value {
  position: absolute;
  left: 0;
  top: 0;
  right: 40px;
  bottom: 0;
  box-sizing: border-box;
  padding: var(--cs-form-input-padding-x);
  background-color: #0f0;
}
.ecs-seleeca-search--open .cs-seleeca-search__value {
  display: none;
}

/* Results */
.ecs-seleeca-search__results-wrapper {
  height: 0px;
}
.ecs-seleeca-search__results {
  box-sizing: border-box;
  border: solid 1px var(--cs-gray-02);
  border-top: none;
  background-color: var(--cs-gray-00);
  border-bottom-left-radius: var(---ecs-seleeca-search-border-radius);
  border-bottom-right-radius: var(---ecs-seleeca-search-border-radius);
  position: relative;
  max-height: 40vh;
  overflow-y: auto;
  opacity: 0;
  transform: scaleY(0) translateY(-50px);
  transform-origin: top;
  transition: transform 0.2s, opacity 0.2s, border-color 0.2s;
}
.ecs-seleeca-search--open .ecs-seleeca-search__results {
  border-color: var(--cs-gray-03);
  opacity: 1;
  transform: scaleY(1) translateY(0);
  z-index: 1;
}

.ecs-seleeca-search__option-group-label {
  font-size: var(--cs-font-size-50);
  text-transform: var(--cs-transform-upper);
  cursor: default;
  color: var(--cs-gray-04);
  padding: 12px;
  padding-top: 15px;
}
.ecs-seleeca-search__option-group-item,
.ecs-seleeca-search__option-group-item--no-results {
  font-size: var(--cs-font-size-100);
  white-space: normal;
  color: var(--cs-gray-07);
  padding: 11px;
  padding-top: 8px;
  outline: none;
  border: 1px solid var(--cs-gray-02);
}
.ecs-seleeca-search__option-group-item--indent {
  padding-left: 25px;
}
.ecs-seleeca-search__option-group-item--selected,
.ecs-seleeca-search__option-group-item:focus,
.ecs-seleeca-search__option-group-item:hover {
  background-color: var(--cs-gray-02);
  cursor: pointer;
}
</style>
