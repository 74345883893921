<template>
  <div class="eca-sidebar">
    <div class="eca-sidebar__logo-container">
      <img :src="logo" class="eca-sidebar__logo" />
    </div>
    <!-- Services -->
    <ul>
      <li v-for="(link, index) in navLinks" :key="index">
        <router-link
          :to="serviceRoute(link)"
          :class="isActiveRoute(link) ? 'eca-sidebar__active-items' : ''"
          class="eca-sidebar__none-active-items"
        >
          <span class="eca-sidebar__items">
            <i :class="link.icon" />
            {{ link.text }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
const logo = require('@/assets/images/logo.svg');

export default {
  props: {},
  data() {
    return {
      logo,
      navLinks: [
        {
          text: 'Dashboard',
          path: '',
          name: 'Dashboard',
          icon: 'cs-icons-home-filled',
        },
        {
          text: 'Accounts',
          path: 'accounts',
          name: 'Accounts',
          icon: 'cs-icons-doc-filled',
        },
        {
          text: 'Recommendations',
          path: 'recommendations',
          name: 'Recommendations',
          icon: 'cs-icons-file-convert-filled',
        },
      ],
    };
  },
  methods: {
    isActiveRoute(link) {
      return this.$route.name.includes(link.name);
    },
    serviceRoute(link) {
      const path = link.extra ? `${link.path}/${link.extra}` : link.path;
      return `/${path}`;
    },
  },
};
</script>

<style scoped>
.eca-sidebar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #040521;
  position: relative;
}
.eca-sidebar__logo-container {
  height: 65px;
  border-bottom: 1px solid var(--cs-gray-05);
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.eca-sidebar__logo {
  height: 20px;
}

ul {
  display: flex;
  align-items: center;
  padding-inline-start: 0;
  flex-direction: column;
  margin: 0px;
}
a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: white;
}
a:hover {
  color: var(--cs-gray-00);
}
i {
  margin-right: 10px;
  font-size: 22px;
}
li {
  list-style-type: none;
  width: 100%;
}
.eca-sidebar__active-items {
  background-color: var(--cs-primary-base) !important;
  color: var(--cs-gray-00) !important;
  border-color: inherit !important;
}
.eca-sidebar__sub-active-items {
  background-color: var(--cs-primary-dark) !important;
  color: var(--cs-gray-00) !important;
  border-color: inherit !important;
}
.eca-sidebar__items {
  padding: 10px 20px;
  font-weight: 400;
  font-size: 14px;
}
.eca-sidebar__sub-items {
  padding: 10px 0px 10px 40px;
  font-weight: 400;
  margin-left: 15px;
  font-size: 12px;
}
.eca-sidebar__none-active-items {
  background: transparent;
}
.eca-sidebar__bottom-active-items {
  background: var(--cs-primary-base);
}
</style>
