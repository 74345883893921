<template>
  <div class="col-md-10 ecs-topbar__search-bar__input-header">
    <div class="ecs-topbar__search-bar__container">
      <div class="ecs-topbar__search-bar">
        <app-select-search
          placeholder="Search"
          :options="searchOptions"
          @input="onInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppSelectSearch from '@/components/topbar/SelectSearch.vue';

export default {
  components: {
    AppSelectSearch,
  },
  props: {
    lastActiveProject: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      searchOptions: [
        { value: 'Dashboard', label: 'Dashboard' },
        { value: 'Accounts', label: 'Accounts' },
        { value: 'Recommendations', label: 'Recommendations' },
      ],
    };
  },
  methods: {
    onInput(selectedItem) {
      if (selectedItem) {
        this.$router.push({
          name: selectedItem,
          params: { id: this.lastActiveProject.id },
        });
      }
    },
  },
};
</script>

<style scoped>
.ecs-topbar__search-bar i {
  color: var(--cs-gray-04);
}
.ecs-topbar__search-bar__input-header {
  display: flex;
  align-items: center;
}
.ecs-topbar__search-bar__container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
.ecs-topbar__search-bar {
  background-color: var(--cs-gray-00);
  display: flex;
  flex: 1;
  align-items: center;
}
.ecs-seleeca-search >>> label {
  border: none !important;
}
.cs-icons-search {
  color: var(--cs-gray-03) !important;
}
.ecs-seleeca-search >>> input::placeholder {
  color: var(--cs-gray-03);
}
.ecs-seleeca-search--open >>> label {
  border: 1px solid var(--cs-gray-03) !important;
  border-radius: 0px !important;
}
</style>
