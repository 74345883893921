<template>
  <div class="eca-accounts-new">
    <cs-card>
      <div slot="body">
        <div class="eca-accounts-new__body">
          <h1 class="cs-textstyle-page-title">Accounts</h1>
          <p class="cs-textstyle-paragraph-bold mt-2">
            Cloud Advisor helps you improve the efficiency, reliability, and
            security of your backend systems. <br />
            Connect your cloud accounts and code repositories to start.
          </p>
          <div class="eca-accounts-new__connections mt-5">
            <cs-button
              corners="rounded"
              variant="secondary"
              @click="onShowAwsModal"
            >
              <img :src="awsImgUrl" />
            </cs-button>
            <cs-button
              corners="rounded"
              variant="secondary"
              @click="onShowGithubModal"
            >
              <img :src="githubImgUrl" />
            </cs-button>
          </div>
        </div>
      </div>
    </cs-card>
    <app-aws-modal
      :show="showAwsModal"
      @close-aws-modal="onCloseAwsModal"
      @permissions-given="onCloseAfterAwsPermissionsGiven"
    />
    <app-github-modal
      :show="showGithubModal"
      @close-github-modal="onCloseGithubModal"
      @permissions-given="onCloseAfterGithubPermissionsGiven"
    />
  </div>
</template>

<script>
import ListConnections from '@/gql/accounts/ListConnections.gql';
import AppAwsModal from '@/components/accounts/awsModal.vue';
import AppGithubModal from '@/components/accounts/githubModal.vue';

const awsImgUrl = require('@/assets/images/AWS.svg');
const githubImgUrl = require('@/assets/images/Github.svg');

export default {
  components: {
    AppAwsModal,
    AppGithubModal,
  },
  apollo: {
    connections: ListConnections,
  },
  data() {
    return {
      awsImgUrl,
      githubImgUrl,
      showAwsModal: false,
      showGithubModal: false,
    };
  },
  watch: {
    connections(newVal) {
      if (newVal && newVal.length) {
        this.$router.push('/accounts/list');
      }
    },
  },
  methods: {
    onShowAwsModal() {
      this.showAwsModal = true;
    },
    onCloseAwsModal() {
      this.showAwsModal = false;
    },
    async onCloseAfterAwsPermissionsGiven() {
      this.showAwsModal = false;
      // TODO: Redirect to the accounts list page.
    },
    onShowGithubModal() {
      this.showGithubModal = true;
    },
    onCloseGithubModal() {
      this.showGithubModal = false;
    },
    async onCloseAfterGithubPermissionsGiven() {
      this.showGithubModal = false;
      // TODO: Redirect to the accounts list page.
    },
  },
};
</script>

<style scoped>
.eca-accounts-new >>> .cs-card {
  background: #f9fbfa;
  height: 750px;
  display: grid;
  place-items: center;
  width: 100%;
}

.eca-accounts-new__body {
  text-align: center;
}
.eca-accounts-new__body h1 {
  color: var(--cs-black);
}
.cs-textstyle-paragraph-bold {
  color: var(--cs-gray-05);
}
.eca-accounts-new__connections {
  display: flex;
  justify-content: center;
  gap: 20px;
}
</style>
