import { render, staticRenderFns } from "./githubModal.vue?vue&type=template&id=a7e8ebc8&scoped=true&"
import script from "./githubModal.vue?vue&type=script&lang=js&"
export * from "./githubModal.vue?vue&type=script&lang=js&"
import style0 from "./githubModal.vue?vue&type=style&index=0&id=a7e8ebc8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7e8ebc8",
  null
  
)

export default component.exports