<template>
  <div>
    <div :id="`showPopover${index}`">
      <slot name="visible"></slot>
    </div>
    <b-popover
      ref="actionPopover"
      :target="`showPopover${index}`"
      triggers="hover"
      custom-class="bg-dark"
      placement="top"
    >
      <div class="eca-dashboard__popover">
        <slot name="popover"></slot>
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: String,
      required: true,
    },
  },
};
</script>
