<template>
  <div class="eca-acconts-list">
    <div class="eca-dashboard__header">
      <div>
        <div class="cs-textstyle-page-title">Accounts</div>
        <p class="cs-textstyle-paragraph">
          You can add a new account to your cloud or code repository from this
          page. You can see all the connected accounts you have with Efficient
          Cloud.
        </p>
      </div>
      <div class="eca-accounts-list__connections">
        <cs-button
          corners="rounded"
          variant="secondary"
          @click="onShowAwsModal"
        >
          <img :src="awsImgUrl" />
        </cs-button>
        <cs-button
          corners="rounded"
          variant="secondary"
          @click="onShowGithubModal"
        >
          <img :src="githubImgUrl" />
        </cs-button>
      </div>
    </div>
    <div
      v-if="$apollo.queries.connections.loading"
      class="eca-spinner__wrapper"
    >
      <cs-spinner class="eca-spinner" />
    </div>
    <div v-if="connections && connections.length">
      <div class="eca-account-list__search">
        <cs-search-bar
          v-model="val"
          class="eca-account-list__search-bar"
          placeholder="Search Accounts"
        />
        <i
          class="cs-icons-excel eca-account-list__search-bar-icon eca-cursor"
          @click="downloadFile"
        ></i>
      </div>
      <div class="eca-dashboard__table-head">
        <b-table
          :items="connections"
          :fields="fields"
          borderless
          hover
          :filter="filteredResult"
          table-variant="light"
          tbody-tr-class="eca-dashboard__table-row"
        >
          <template #table-busy>
            <div class="eca-dashboard__table-busy">
              <cs-spinner class="eca-spinner" />
            </div>
          </template>
          <!-- ID -->
          <template v-slot:cell(id)="data">
            <app-hover :index="`${data.index}${data.field.key}`">
              <app-truncated-text slot="visible" :long-text="data.item.id" />
              <div slot="popover">{{ data.item.id }}</div>
            </app-hover>
          </template>
          <!-- Time -->
          <template v-slot:cell(created_at)="data">
            <span>
              {{ data.item.created_at | moment('DD MMM YYYY, hh:mm A') }}
              (UTC)
            </span>
          </template>
          <!-- Permission Level -->
          <template v-slot:cell(permission_level)="data">
            <span v-if="data.item.permission_level">
              <cs-tag class="eca-tag">{{ data.item.permission_level }}</cs-tag>
            </span>
          </template>
          <!-- Granted By -->
          <template v-slot:cell(granted_by)="data">
            <span v-if="data.item.user">
              <cs-tag class="eca-tag">{{
                data.item.user.first_name + ' ' + data.item.user.last_name
              }}</cs-tag>
            </span>
          </template>
        </b-table>
      </div>
    </div>
    <!-- Empty State -->
    <div v-if="connections && !connections.length">
      <app-empty-state
        :icon="Image"
        :show-button="false"
        description="You have not created any job yet"
      />
    </div>
    <app-aws-modal
      :show="showAwsModal"
      @close-aws-modal="onCloseAwsModal"
      @permissions-given="onCloseAfterAwsPermissionsGiven"
    />
    <app-github-modal
      :show="showGithubModal"
      @close-github-modal="onCloseGithubModal"
      @permissions-given="onCloseAfterGithubPermissionsGiven"
    />
  </div>
</template>

<script>
import ListConnections from '@/gql/accounts/ListConnections.gql';
import AppTruncatedText from '@/components/general/TruncatedText.vue';
import AppEmptyState from '@/components/general/EmptyState.vue';
import AppHover from '@/components/general/Hover.vue';
import $excel from '@/services/excel';
import AppAwsModal from '@/components/accounts/awsModal.vue';
import AppGithubModal from '@/components/accounts/githubModal.vue';

const awsImgUrl = require('@/assets/images/AWS.svg');
const githubImgUrl = require('@/assets/images/Github.svg');
const Image = require('@/assets/images/image.svg');

export default {
  components: {
    AppTruncatedText,
    AppEmptyState,
    AppHover,
    AppAwsModal,
    AppGithubModal,
  },
  apollo: {
    connections: ListConnections,
  },
  data() {
    return {
      awsImgUrl,
      githubImgUrl,
      showAwsModal: false,
      showGithubModal: false,
      Image,
      val: '',
      fields: [
        {
          key: 'id',
          label: 'Job Id',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'created_at',
          label: 'Time',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'type',
          label: 'Type',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'provider',
          label: 'Provider',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'account_id',
          label: 'Account ID',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'permission_level',
          label: 'Permission Level',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'granted_by',
          label: 'Granted By',
          thClass: 'eca-dashboard__table-head',
        },
      ],
    };
  },
  computed: {
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  methods: {
    // Download list into an excel file
    downloadFile() {
      const fields = [
        'id',
        'created_at',
        'type',
        'provider',
        'account_id',
        'permission_level',
        'granted_by',
      ];
      const output = this.connections.map((item) => {
        const job = {
          ...item,
          granted_by:
            item.user == null
              ? null
              : `${item.user.first_name}  ${item.user.last_name}`,
        };
        return job;
      });
      $excel.export(output, fields, 'Accounts List');
    },
    onShowAwsModal() {
      this.showAwsModal = true;
    },
    onCloseAwsModal() {
      this.showAwsModal = false;
    },
    async onCloseAfterAwsPermissionsGiven() {
      this.showAwsModal = false;
      // TODO: Redirect to the accounts list page.
    },
    onShowGithubModal() {
      this.showGithubModal = true;
    },
    onCloseGithubModal() {
      this.showGithubModal = false;
    },
    async onCloseAfterGithubPermissionsGiven() {
      this.showGithubModal = false;
      // TODO: Redirect to the accounts list page.
    },
  },
};
</script>

<style scoped>
.eca-acconts-list p.cs-textstyle-paragraph {
  color: var(--cs-gray-05);
}
.eca-account-list__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 0px 20px 0px;
}
.eca-account-list__search-bar {
  width: 100%;
}
.eca-account-list__search-bar-icon {
  margin-left: 20px;
  font-size: 28px;
}
.videoinsight-history__feature {
  margin: 5px;
}
.eca-account-list__features {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  vertical-align: middle;
}
.eca-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.eca-spinner__wrapper .eca-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
.eca-accounts-list__connections {
  display: flex;
  justify-content: center;
  gap: 20px;
}
</style>
