<template>
  <div>
    <cs-modal :show="show" class="eca-aws-modal" @close="onClose">
      <div slot="header">Connect to AWS</div>
      <div slot="body">
        <div class="text__primary mt-4">Permissions Required</div>
        <ul>
          <li class="parent mt-2">
            <div class="cs-textstyle-paragraph-bold">
              Tap "Connect to AWS" to give Cloud Advisor read-only access to an
              AWS account.
            </div>
            <div class="mt-3">
              Cloud Advisor needs read-only access to run ongoing efficiency and
              security analysis. Cloud Advisor only looks at metadata related to
              your use of cloud resources - it will NOT read or store any
              sensitive information.
            </div>
          </li>
          <li class="parent mt-4">
            <div class="cs-textstyle-paragraph-bold">Cloud Advisor will:</div>
          </li>
          <li class="child mt-2">
            Review the CPU and memory utilisation of your EC2 instances.
          </li>
          <li class="child mt-2">
            Assess storage and access patterns on your S3 buckets.
          </li>
          <li class="child mt-2">Look for anomalies in your spending data.</li>
          <li class="parent mt-4">
            <div class="cs-textstyle-paragraph-bold">
              Cloud Advisor will not:
            </div>
          </li>
          <li class="child mt-2">Access any database content</li>
          <li class="child mt-2">Read any stored files</li>
        </ul>
        <cs-alert
          class="mt-4"
          icon="cs-icons-info"
          show-icon
          variant="success"
          content="All data access is governed by our strict Non-Disclosure, Privacy, and Security policies."
        >
        </cs-alert>
        <div>
          If you have any questions, we are here to help.
          <a href="#" class="text__primary">Contact Support</a>
        </div>
        <cs-button
          slot="main"
          class="mt-5 eca-aws-modal__action"
          variant="primary"
          corners="rounded"
          @click="onLaunchStack"
          >Launch Stack</cs-button
        >
      </div>
    </cs-modal>
  </div>
</template>

<script>
import $auth from '@/services/auth';

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$emit('close-aws-modal');
    },
    onLaunchStack() {
      window.location = `https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review?param_EfficientCloudCustomerId=${$auth.getUserId()}&stackName=efficient-cloud-advisor&templateURL=https://efficient-cloud-advisor-connect.s3.amazonaws.com/cloud-advisor-iam-role.yaml`;
    },
  },
};
</script>

<style scoped>
.eca-aws-modal {
  ---cs-modal-width: 550px !important;
  ---cs-modal-max-height: 489px !important;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
li {
  display: block;
  background-repeat: no-repeat;
  padding-left: 30px;
  padding-top: 5px;
}
li.parent {
  background-image: url('~@/assets/images/Ellipse.svg');
  background-position: left 11px;
}
li.child {
  background-image: url('~@/assets/images/Line.svg');
  background-position: left 18px;
}
.eca-aws-modal__action {
  float: right;
}
.eca-aws-modal .text__primary {
  color: var(--cs-primary-base);
}
</style>
