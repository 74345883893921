import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/login/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
// Accounts
import Accounts from '@/views/accounts/Accounts.vue';
import AccountsNew from '@/views/accounts/AccountsNew.vue';
import AccountsList from '@/views/accounts/AccountsList.vue';
// Recommendations
import Recommendatations from '@/views/Recommendations.vue';

import $auth from '@/services/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    redirect: '/accounts',
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    redirect: '/accounts/new',
    children: [
      {
        path: 'new',
        name: 'AccountsNew',
        component: AccountsNew,
      },
      {
        path: 'list',
        name: 'AccountsList',
        component: AccountsList,
      },
    ],
  },
  {
    path: '/recommendations',
    name: 'Recommendations',
    component: Recommendatations,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  // next();

  if ($auth.isLoggedIn() && to.fullPath.includes('/login')) {
    // Logged in but trying to access an onboarding screen
    next('/');
  } else if (!$auth.isLoggedIn() && !to.fullPath.includes('/login')) {
    // Logged out but trying to access an app screen
    next('/login');
  } else {
    next();
  }
});

export default router;
