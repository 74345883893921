<template>
  <div id="app">
    <b-row v-if="userId" class="g-0">
      <b-col md="2">
        <!-- Sidebar -->
        <app-sidebar />
        Sidebar
      </b-col>
      <b-col md="10" class="body-container">
        <!-- Topbar -->
        <app-topbar />
        <div class="body">
          <router-view />
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <router-view />
    </b-row>
  </div>
</template>

<script>
import $auth from '@/services/auth';
// Component
import AppSidebar from '@/components/general/Sidebar.vue';
import AppTopbar from '@/components/general/Topbar.vue';
export default {
  name: 'App',
  components: {
    AppTopbar,
    AppSidebar,
  },
  data() {
    return {
      userId: $auth.getUserId(),
      breadcrumbs: [],
    };
  },
  watch: {
    $route: {
      handler(to) {
        this.getBreadCrumbs(to);
        if (to && to.name === 'Login') {
          this.userId = $auth.getUserId();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getBreadCrumbs(to) {
      const breadcrumbs = to.meta.breadcrumbs ? to.meta.breadcrumbs : [];
      this.breadcrumbs = breadcrumbs.map((v) =>
        v.replace(`#id`, to.params.id).replace(`#name`, to.params.name)
      );
    },
  },
};
</script>

<style scoped>
#app {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.body-container {
  background: #ffffff;
  height: 100vh;
}
.body {
  padding: 30px;
  height: 100%;
  overflow: auto;
}
</style>
