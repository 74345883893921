<template>
  <b-row class="eca-login">
    <b-col cols="4">
      <div class="eca-login__left">
        <div class="eca-login__logo">
          <img :src="logo" />
          <h2 class="cs-textstyle-page-title mt-4">
            The efficient way to reduce cloud waste.
          </h2>
        </div>

        <img :src="pattern" class="eca-login__pattern" />
        <img :src="illustration" class="eca-login__illustration" />
      </div>
    </b-col>
    <b-col cols="8" class="eca-login__right">
      <div class="eca-login__right--body">
        <cs-card class="eca-login__right--card">
          <div slot="header" class="eca-login__right--title">
            <div class="cs-textstyle-section-heading">Welcome!</div>
            <div class="cs-textstyle-paragraph eca-login__info">
              Login to Efficient Cloud Advisor.
            </div>
          </div>
          <div slot="body" class="eca-login__right--buttons">
            <cs-login-button
              v-for="provider in providers"
              :key="provider"
              :network="provider"
              class="eca-login__button"
              @click="login(provider)"
            >
              {{
                provider === 'google'
                  ? 'Sign in with Google'
                  : 'Sign in with Microsoft'
              }}
            </cs-login-button>
          </div>
        </cs-card>
        <div class="mt-4 eca-login__aggrement">
          By signing up you agree to the
          <a target="_blank" href="/legal/terms.html">Terms of Service</a>
          <br />
          and <a target="_blank" href="/legal/privacy.html">Privacy Policy</a>
        </div>
      </div>
      <div class="eca-login__footer">
        <div class="mx-2">
          <a href="mailto:hello@efficientcloud.com">Contact Us</a>
        </div>
        <div class="mx-2">
          <a target="_blank" :href="docsUrl">Help</a>
        </div>
        <div class="mx-2">
          <a target="_blank" href="/legal/terms.html">Terms of Service</a>
        </div>
        <div class="mx-2">
          <a target="_blank" href="/legal/privacy.html">Privacy Policy</a>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
const logo = require('@/assets/images/logo.svg');
const pattern = require('@/assets/images/pattern.svg');
const illustration = require('@/assets/images/Illustration.svg');
export default {
  data() {
    return {
      logo,
      pattern,
      illustration,
      providers: ['google', 'microsoft'],
    };
  },
  computed: {
    docsUrl() {
      return process.env.VUE_APP_DOCS;
    },
  },
  mounted() {
    if (this.$route.hash) {
      const token = this.$route.hash.split('#access_token=')[1];
      if (token) {
        localStorage.access_token = token;
        this.$router.push('/');
        window.location.reload();
      }
    }
  },
  methods: {
    async login(provider) {
      const providerId = provider.toUpperCase();
      window.location = `${process.env.VUE_APP_REST_ENDPOINT}/login?provider=${providerId}&callback=${window.location.origin}`;
    },
  },
};
</script>

<style scoped>
.eca-login {
  background-color: var(--cs-default-lightest);
  height: 100vh;
}
.eca-login__left {
  display: flex;
  flex-direction: column;
  /* background: var(--cs-primary-base); */
  background: #0e0849;
  height: 100vh;
  justify-content: space-between;
  position: relative;
}
.eca-login__logo {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0%);
}
.eca-login__logo h2 {
  color: var(--cs-white);
}
.eca-login__illustration {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.eca-login__illustraion {
  width: 100%;
  height: 100%;
}

.eca-login__right--card {
  width: 450px;
  padding: 10px;
}
.eca-login__info {
  color: var(--cs-gray-05);
  margin-top: 5px;
}
.eca-login__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--cs-accent-dark);
  height: 150px;
}
.eca-login__right--title {
  padding: 10px 20px;
}
.eca-login__right {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

.eca-login__right--body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.eca-login__right--body >>> .cs-card {
  border: 1px solid var(--cs-default-lightest);
}
.eca-login__right--buttons {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 20px;
}
.eca-login__aggrement {
  text-align: center;
}
.eca-login__aggrement a {
  color: var(--cs-primary-base);
}
.eca-login__button {
  --cs-button-padding: 13px !important;
}
.eca-login__footer {
  border-top: 1px solid var(--cs-gray-02);
  display: flex;
  padding: 30px;
  justify-self: flex-end;
  justify-content: center;
  flex-direction: row;
  color: var(--cs-gray-04);
  gap: 30px;
}
.row > * {
  padding: 0px;
}
</style>
