<template>
  <div class="eca-recommendations">
    <h1 class="cs-textstyle-page-title">Recommendations</h1>
    <cs-card>
      <div slot="body">
        <div class="cs-textstyle-page-subtitle">Coming soon...</div>
      </div>
    </cs-card>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.eca-recommendations >>> .cs-card {
  background: #f9fbfa;
  height: 750px;
  display: grid;
  place-items: center;
}
.cs-textstyle-page-subtitle {
  color: var(--cs-default-base);
}
</style>
